export default {
  blogerName: 'CASAL',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@Casalwin_',
    },
    {
      name: 'instagram',
      url: 'https://instagram.com/casalwin_',
    },
    {
      name: 'telegram',
      url: 'https://t.me/casalwin',
    }
  ],
  projects: [
    {
      name: 'lex',
      url: 'https://lex-irrs01.com/ced33b505?activation_code=CASAL',
      gameTitle: 'Book of Kemet',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: '1go',
      url: 'https://1go-irit.com/c05826c5a?activation_code=CASAL',
      gameTitle: 'Book of Kemet',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs12.com/cbd060df2?activation_code=CASAL',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs12.com/c45699851?activation_code=CASAL',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs10.com/c6bc7d8f8?activation_code=CASAL',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs10.com/cc7d8cc0c?activation_code=CASAL',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/cb0e750d1?activation_code=CASAL',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/ceb8d3245?activation_code=CASAL',
      gameTitle: 'Jet Air (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/ccb7668f9?activation_code=CASAL',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/cb1b3bbf3?activation_code=CASAL',
      gameTitle: 'Izzi Art (BGaming)',
    },

  ],
  headerText: 'Cadastre-se com um código promocional <strong>CASAL</strong> e receba 125 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'CASAL',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>125FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark'
}
